import { Chip, ChipProps, Typography } from "@mui/material";

type Props = ChipProps & {
  type: "leader" | "new" | "leaderBox";
};

const badgeTypeChipProps: Record<string, ChipProps> = {
  leader: {
    label: "Member Leader",
    color: "primary",
  },
  new: {
    label: "New member",
    color: "warning",
  },
};

export default function Badge({ type, sx, ...otherProps }: Props) {
  const isLeaderBox = type === "leaderBox";

  return (
    <Chip
      color={isLeaderBox ? "default" : badgeTypeChipProps[type].color}
      label={
        isLeaderBox ? (
          <Typography
            sx={{ color: (theme) => theme.palette.primary.contrastText }}
            variant="subtitle2"
          >
            Member Leader
          </Typography>
        ) : (
          badgeTypeChipProps[type].label
        )
      }
      size="small"
      sx={{
        ...(isLeaderBox && {
          backgroundColor: (theme) => theme.palette.grey[800],
          color: (theme) => theme.palette.primary.contrastText,
          borderRadius: "4px",
          minHeight: (theme) => theme.spacing(3.8),
          minWidth: "175px",
        }),
        fontWeight: 500,
        lineHeight: "20px",
        fontSize: "11px",
        height: (theme) => theme.spacing(2.3),
        ...(!isLeaderBox && {
          px: "1px",
        }),
        ...sx,
      }}
      {...otherProps}
    />
  );
}
